import React, { useState } from 'react'
import Layout from 'elements/Frame/Layout'
import Title from 'elements/Typography/Title'
import SubscribeForm from 'views/Subscribe/SubscribeForm'
import {
  Text
} from '@chakra-ui/core'
import { SUBSCRIBE_API_ROUTE } from 'config/routes'

export default function SubscribePage () {
  const [isSuccess, setIsSuccess] = useState(false)

  const onSubmit = async ({ email }) => {
    try {
      await fetch(SUBSCRIBE_API_ROUTE, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, action: 'subscribe' })
      })
      setIsSuccess(true)
    } catch (e) {
      // Fail silent for now
      setIsSuccess(true)
    }
  }
  return (
    <Layout>
      <Title label="Subscribe to newsletter">Subscribe to our newsletter</Title>

      {isSuccess ? (
        <Text>Sweet! thanks for joining!</Text>
      ) : (
        <Text> Our signup is currently in invite mode. Please subscribe to our newsletter to get on our invite list and to stay in touch with our general happenings.</Text>
      )}
      <SubscribeForm onSubmit={onSubmit}/>

    </Layout>
  )
}
